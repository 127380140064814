<template>
  <div>
    <div class="section-header mb-n2">
      <h4 style="font-weight: 500">Plafonds de crédit</h4> 
      <div>
        <mdb-btn
          class="float-right"
          tag="a"
          color="primary"
          icon="plus"
          size="sm"
          small
          v-if="$store.state.auth.user.is_approval_credit_plafond === 0 && $gate.hasPermission(['create::credit-plafond-customer'])" 

          @click="addOrEdit()"
        >
        </mdb-btn>
      </div>
    </div>
    <hr class="" />
    
    <mdb-row>
      <mdb-col sm="12" md="12" lg="12" class="mb-4 mx-0 mx-auto">
        <mdb-card cascade>
          <mdb-card-body :class="'card-reversed'" cascade v-loading="loading">
            <div class="tool-sp">
              <div></div>
              <mdb-btn v-if="false"
                class="float-right"
                tag="a"
                color="primary"
                outlined
                size="sm"
                small
                @click="creditReport()"
              >
              Exporter
              </mdb-btn>
            </div>
            <div class="tool-sp">
                <mdb-select
                  flipOnScroll
                  label="Nombre par page"
                  outline
                  class=""
                  @getValue="selected"
                  v-model="pagination.pagination.perPageOptions"
                />
                <mdb-input
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  icon="search"
                  outline
                  type="text"
                  v-model="searchQuery"
                  @change="search()"
                  placeholder="Rechercher"
                  aria-label="Search"
                />
            </div>
            

            <mdb-tbl responsiveMd >
              <mdb-tbl-head>
                <tr class="color-normal-reversed">
                  <th v-for="column in data.columns" :key="column.name">
                    {{ column.label }}
                  </th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body>
                <tr
                  class="tec-table-row color-normal-reversed"
                  v-for="(row,index) in queriedData"
                  :key="index"
                >
                <td style="font-weight:400" > {{row.customer.designation}} </td>
                <td style="font-weight:400;text-align: left;" > {{row.credit_plafond | formatNumber}} XOF </td>
                <td style="font-weight:400;" > {{row.initiated_by.firstname }} {{row.initiated_by.lastname }}  </td>
                <td style="font-weight:500" >{{$moment(row.created_at).format('DD/MM/YYYY à H:mm:ss')}}</td>
                <td style="font-weight:400" > 
                  <span style="box-shadow:none" v-if="row.status === 'progress'" class="badge badge-warning">En cours</span>
                  <span style="box-shadow:none" v-if="row.status === 'validated'" class="badge badge-primary">Validé</span>
                  <span style="box-shadow:none" v-if="row.status === 'rejected'" class="badge badge-danger">Rejeté</span>
                </td>
                  <td class="d-flex justify-content-end">
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        style="cursor: pointer; font-weight: 500"
                      >
                        Actions<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>

                      <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                        <el-dropdown-item
                          class="dropdown-item-el-tec"
                          icon="el-icon-s-order"
                          @click.native="details(row)"
                          v-if="$gate.hasPermission(['view::credit-plafond-customer'])"
                          >Détails du plafond</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="addOrEdit(row)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-edit"

                          v-if="$store.state.auth.user.is_approval_credit_plafond === 0 && $gate.hasPermission(['edit::credit-plafond-customer'])"
                          >Modifier le plafond</el-dropdown-item
                        >
                        <el-dropdown-item
                          v-if="$store.state.auth.user.is_approval_credit_plafond === 0 && dateEquals(row.created_at,row.updated_at) && $gate.hasPermission(['delete::credit-plafond-customer'])"
                          @click.native="deletePlafond(row.id)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-delete"
                          >Supprimer le plafond</el-dropdown-item
                        >
                      </el-dropdown-menu> 
                    </el-dropdown>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>

            <div v-if="empty" class="d-flex justify-content-center w-100 p-5">
              <img
                :src="require('../../../../assets/svg/empty_streec.svg')"
                class="empty"
                alt=""
                srcset=""
              />
            </div>

            <div class="d-flex justify-content-end">
              <tec-pagination
                v-model="pagination.pagination.currentPage"
                :per-page="parseFloat(pagination.pagination.perPage)"
                :total="pagination.pagination.total"
                v-on:changePageEvent="loadCreditBack"
              ></tec-pagination>
            </div>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
} from "mdbvue";
import TecPagination from "@/tba/components/PaginationUrl";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    TecPagination,
  },
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    pageData: {
      type: Array,
      default: () => [],
    },
    queriedData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {

    addOrEdit(data = null) {
      this.$emit("add_or_edit", data);
    },

    details(data) {
      this.$emit("details", data);
    },

    creditReport(e) {
      this.$emit("credit_report", e);
    },

    deletePlafond(e){
      this.$emit("delete_plafond", e);
    },

    selected(e) {
      this.$emit("per_page_selected", e);
    },

    dateEquals(d1,d2){
      return this.$moment(d1).isSame(d2); 
    },  

    nbrPerPageSelected(value) {
      this.$emit("per_page_selected", value);
    },
    loadCreditBack(e) {
      this.$emit("load_credit_back", {
        page: e,
        perPage: this.pagination.pagination.perPage,
      });
    },
    search() {
      this.$emit("search_q", this.searchQuery);
    },
    },
};
</script>
