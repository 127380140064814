<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class=" " style="font-weight:400" >Plafonds de crédit</h5>
            <div>
                
                <mdb-btn
                    class="float-right"
                    tag="a"
                    color="primary"
                    icon="plus"
                    size="sm" 
                    small
                    @click="addOrEdit(null)"
                    v-if="$gate.hasPermission(['create::credit-plafond-customer'])"
                >
                </mdb-btn>
                <mdb-btn
                    class="float-right"
                    tag="a"
                    color="primary"
                    icon="download"
                    size="sm" 
                    small 
                    v-if="false"
                    @click="creditReport()"
                >
                </mdb-btn>
            </div>
        </div>
        <div class="card card-search-os mb-4 px-0 py-0" style="border-radius:10px;">
            <div class="card-body px-0 py-0"> 
                <div class="tec-search-v-icon">
                    <input   
                    v-model="searchQuery"
                    @input="search()"
                    type="search" 
                    ref="searchbar" name="search" placeholder="recherche ..." 
                    id="tec-search-v" class="tec-search-v" style="background:transparent">
                    <i class="fa fa-search fa-md fa-fw" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="card card-list-os" v-if="empty" v-loading="loading" >
            <div class="card-body text-center">
                <div  class="d-flex justify-content-center w-100 p-5">
                    <img  class="w-50" :src="require('../../../../assets/svg/empty_streec.svg')"   alt="" srcset="">
                </div>
                    <h6>Aucune donnée</h6>
            </div>
        </div>
        <div class="" v-if="!empty" v-loading="loading" >
            <div class="card card-list-os mb-3" style="border-radius:10px!important;box-shadow:0px 0px 4px 1px rgb(0,0,0,0.15) !important"  v-for="row in queriedData" :key="row.id">
                <div class="card-body py-3 pl-2 d-flex align-items-center justify-content-between" >
                    <div class="" style="max-width:90%">
                        <div class="mobile-card-header" style="">
                            <span>  </span>
                            <span class="badger pb-2 d-block" style="font-size:0.8rem">
                                <span v-if="row.status === 'validated'" class="badge badge-success"> validé </span>
                                <span v-else-if="row.status === 'rejected'" class="badge badge-danger"> rejeté </span>
                                <span v-else class="badge badge-warning"> en cours</span>
                            </span>
                        </div>
                        <h5 class="card-title  pb-2 "  
                            style="font-size:0.9rem;font-weight:700;font-family:'Nunito'"> 
                            <span style="text-transform:uppercase">
                            {{ row.customer.designation}} | {{row.credit_plafond | formatNumber}} XOF
                            </span>
                        </h5>
                        <div class="mt-n3 mobile-card-describe" style="font-weight:500;text-transform:uppercase;font-size:0.65rem; color:rgba(62, 69, 81, .7);">
                            <span class="item-mobile-spa">Initié par {{row.initiated_by.firstname }} {{row.initiated_by.lastname.substr(0,1) }}. </span>
                            <span class="item-mobile-spa">le {{$moment(row.created_at).format('DD/MM/YYYY à H:mm:ss')}}</span>
                        </div> 
                    </div>
                    <div class="action">
                        <button class="btn-mob" @click="showAction(row)" >
                            <i class="color-normal-reversed fas fa-ellipsis-v"></i>
                        </button>
                    </div>    
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <tec-pagination
                    v-model="pagination.pagination.currentPage"
                    :per-page="parseFloat(pagination.pagination.perPage)"
                    :total="pagination.pagination.total"
                    v-on:changePageEvent="loadCreditBack"
                ></tec-pagination>
            </div>
        </div>
        <mdb-modal  color="primary" centered size="sm" position="bottom" direction="bottom" :show="modalAction.show" @close="hideAction()">
            <mdb-modal-body class="px-0 py-0">
                <mdb-list-group flush>
                    <mdb-list-group-item  
                        class="bg-primary white-text font-weight-bold" > 
                        <div>MENU</div> 
                        <button class="btn-mob" @click="hideAction()"> 
                            <i class="fas fa-times"></i> 
                        </button> 
                    </mdb-list-group-item>
                    <mdb-list-group-item  class="menu-tec-lg" 
                    v-if="$gate.hasPermission(['view::credit-plafond-customer'])"
                        @click.native="exportPdf(modalAction.content)"
                        icon="eye" style="font-weight:400;" >
                        <div class="pl-3 menu-mob-tec">Details du plafond</div>
                    </mdb-list-group-item>
                    <mdb-list-group-item  class="menu-tec-lg"
                        v-if="$store.state.auth.user.is_approval_credit_plafond === 0 && $gate.hasPermission(['edit::credit-plafond-customer'])" 
                        @click.native="addOrEdit(modalAction.content)"
                        icon="edit" style="font-weight:400;" >
                        <div class="pl-3 menu-mob-tec">Modifier le plafond</div>
                    </mdb-list-group-item>
                    <mdb-list-group-item  
                        v-if="$store.state.auth.user.is_approval_credit_plafond === 0 && dateEquals(modalAction.content.created_at,modalAction.content.updated_at) && $gate.hasPermission(['delete::credit-plafond-customer'])"
                        class="menu-tec-lg text-danger"
                        @click.native="deletePlafond(modalAction.content.id)"   
                        icon="trash"   style="font-weight:400;" ><div class="pl-3 menu-mob-tec">Supprimer le plafond</div>
                    </mdb-list-group-item>
                </mdb-list-group>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>

<script>
import TecPagination from "@/tba/components/PaginationUrl";

import {
    mdbBtn,
    mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
} from 'mdbvue'
export default {
    components:{
        mdbBtn,
        mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
        TecPagination
    },
    props:{
        empty:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        },
        pagination:{
            type:Array,
            default: ()=>[]
        },
        data:{
            type:Array,
            default:() => []
        },
        pageData:{
            type: Array,
            default: () => []
        },
        queriedData:{
            type: Array,
            default: () => []
        },
        total:{
            type: Number,
            default: () => 0
        },
    },
    data(){
        return {
            searchQuery:"",
            modalAction:{
                show:false,
                content:{}
            }
        }
    },
    methods:{
        dateEquals(d1,d2){
            return this.$moment(d1).isSame(d2); 
            },
        showAction(data){
            this.modalAction.content  = data
            this.modalAction.show = true
        },
        hideAction(){
            this.modalAction.content  = {}
            this.modalAction.show = false
        },

        addOrEdit(data = null){
            this.modalAction.show = false
            this.$emit('add_or_edit',data)
        },
        
        creditReport(e) {
            this.modalAction.show = false
            this.$emit("credit_report", e);
        },

        exportPdf(e) {
            this.modalAction.show = false
            this.$emit("export_pdf", e);
        },

        search() {
            this.$emit("search_q", this.searchQuery);
        },

        loadCreditBack(e){
            this.$emit('load_credit_back',{
                page:e,
                perPage:this.pagination.pagination.perPage
            })
        },

        // Systeme de datatable
        totalCalcul () {
            return this.pagination.pagination.total = this.data.credits.total;
        },

        searchQ () {
            if (!this.pagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
            let result = this.data.credits.data.filter((row) => {
                let isIncluded = false;
                for (let key of this.pagination.propsToSearch) {
                    let rowValue = "";
                rowValue = `${row[key]}`.toString().toLowerCase();

                if (key === 'customer_id') {
                    rowValue = `${row['customer'].designation}`.toString().toLowerCase();
                }

                if (key === 'status') {
                    if (row['status'].toLowerCase() === 'en cours') {
                        rowValue = `progress`.toString().toLowerCase();
                    }
                    if (row['status'].toLowerCase() === 'validé') {
                        rowValue = `validated`.toString().toLowerCase();
                    }
                    if (row['status'].toLowerCase() === 'rejeté') {
                        rowValue = `rejected`.toString().toLowerCase();
                    }
                }

                if (key === 'user_id') {
                    rowValue = `${row['initiated_by'].firstname} ${row['initiated_by'].lastname}`.toString().toLowerCase();
                }

                if (key === 'initiated_at') {
                    rowValue = `${this.$moment(row['initiated_at']).format('DD/MM/YYYY à H:mm:ss')}`.toString().toLowerCase();
                }

                if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                isIncluded = true;
                }
                }
                return isIncluded;
            });
            this.pagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
            this.$emit('load_credits',{
                page:1,
                perPage:nbr
            })
           return this.pagination.pagination.perPage = nbr
        },

        deletePlafond(id)
        {
            this.modalAction.show = false
            this.$emit('delete_plafond', id)
		},
    }
}
</script>